<template>
  <div class="h-100">
    <v-select
      dir="rtl"
      v-model="selectedEmployer"
      :options="partnerList"
      label="fullName"
      placeholder="تمام کارفرمایان"
      class="detail-info-el bg-white"
    ></v-select>
    <div class="farm-item-list">
      <div
        v-for="(item, index) in farmsList"
        :key="index"
        @click="GetTips(item.farmId, `${item.farmer} (${item.title})`)"
        :class="
          selectedFarm == item.farmId
            ? 'farm-item farm-item-selected'
            : 'farm-item'
        "
      >
        <img
          @error="
            () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
          "
          :src="item.image"
          alt=""
        />
        <div class="farm-item-info">
          <h5 style="font-weight: bold; color: darkgreen">
            {{ item.farmer }} ({{ item.title }})
          </h5>
          <h5>{{ item.product }} ({{ item.productType }})</h5>
          <h5>
            کشت :{{ item.cultivationsType }} ({{ item.cultivationsDate }})
          </h5>
          <h5>
            {{ item.state }} / {{ item.city }} /{{ item.district }} /
            {{ item.village }}
          </h5>
          <i>{{ item.farmArea }} هکتار</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {getCurrentUser} from "../../../utils/index";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import {apiUrlRoot} from "../../../constants/config";
import vSelect from "vue-select";

export default {
  name: "farmsForm",
  components: {vSelect},
  data() {
    return {
      loading: false,
      partnerList: [],
      farmsList: [],
      selectedEmployer: null,
      currentUserType: getCurrentUser().type,
      selectedFarm: 0,
    };
  },
  watch: {
    selectedEmployer: function () {
      this.GetFarms();
    },
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllPartner", "GetAllPartnerFarms"]),
    async GetPartners() {
      this.loading = true;
      let partners = await this.GetAllPartner({
        currentUserType: this.currentUserType,
      });
      this.loading = false;
      if (partners.succeeded) {
        this.partnerList = partners.data;
        await this.GetFarms();
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${partners.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    async GetFarms() {
      this.loading = true;
      this.farmsList = [];
      let farmListResult =
        this.selectedEmployer == null
          ? await this.GetAllPartnerFarms({
              userId: 0,
              userType: null,
              currentUserType: this.currentUserType,
            })
          : await this.GetAllPartnerFarms({
              userId: this.selectedEmployer.id,
              userType: this.selectedEmployer.userType,
              currentUserType: this.currentUserType,
            });
      this.loading = false;
      if (farmListResult.length > 0) {
        this.farmsList = farmListResult.map((item) => {
          let polygon = item.area.substring(
            item.area.indexOf("("),
            item.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
          item.image = `${apiUrlRoot}Farm/${item.image}`;
          return { ...item };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `هیچ زمینی برای شما ثبت نشده <br /><br />`,
          type: "info",
        });
      }
    },
    GetTips(farmId, farmer) {
      this.selectedFarm = this.selectedFarm == farmId ? 0 : farmId;
      this.$emit("GetTips", this.selectedFarm, farmer);
    },
  },
  mounted() {
    this.GetPartners();
  },
  created() {},
};
</script>

<style scoped>
.farm-item-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.farm-item {
  border-radius: 55px 60px 60px 55px;
  background-color: whitesmoke;
  box-shadow: -2px 2px 6px -4px #000;
  cursor: pointer;
  transition: all 500ms;
}
.farm-item:hover {
  background-color: #5bff92;
}
.farm-item:hover .farm-item-info h5 {
  color: #025823;
}
.farm-item-selected {
  background-color: #5bff92;
}
.farm-item-selected .farm-item-info h5 {
  color: #025823;
}
.farm-item img {
  float: right;
  width: 8em;
  border-radius: 100%;
  height: 13vh;
}
.farm-item-info {
  float: right;
  width: 15vw;
  margin-right: 0.5em;
}
.farm-item-info h5 {
  float: right;
  clear: both;
  width: 100%;
  font-size: 0.9em;
  color: #737373;
  margin: 2px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5em;
}
.farm-item-info i {
  font-size: 1em;
  color: #d04848;
  font-weight: bold;
  font-style: normal;
}
</style>
