<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات  ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="info-box">
      <div class="tips-info">
        <h3>{{ tipsInfo.title }}</h3>
        <p>
          {{ tipsInfo.description }}
        </p>
        <h6>مالک (زمین) : {{ farmTitle }}</h6>
        <h6>تاریخ ثبت : {{ tipsInfo.insertDate }}</h6>
        <h6>تاریخ ویرایش :{{ tipsInfo.updateDate }}</h6>
        <a
          v-for="(item, index) of fileName"
          :key="index"
          :href="item"
          target="_blank"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="reply-box">
      <div class="tips-reply-list">
        <div v-if="tipsReply.length > 0">
          <div v-for="(item, index) of tipsReply" :key="index">
            <div class="reply-info" >
              <h5>
                در همان حال کار آنها به نوعی وابسته به متن می‌باشد آنها با
                استفاده از محتویات ساختگی، صفحه گرافیکی خود را صفحه‌آرایی
                می‌کنند تا مرحله طراحی و صفحه‌بندی را به پایان برند.
              </h5>
              <button>پاسخ به پیام</button>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
            <div class="reply-info-answer">
              <h5>
                آزمایشی و بی‌معنی استفاده می‌کنند تا صرفا به مشتری یا صاحب کار
                خود نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در
                آن قرار گیرد چگونه به نظر می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه
                در نظر گرفته شده‌است. از آنجایی که طراحان عموما
              </h5>
              <button>پاسخ به پیام</button>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
              <a href=""><i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <h5 v-else>هیچ پاسخی ثبت نشده است</h5>
      </div>
      <div class="add-reply">
        <h6 v-if="replyId">
          {{ replyDesc }}
        </h6>
        <textarea
          cols="30"
          rows="10"
          placeholder="متن پیام را وارد کنید ..."
          v-model="description"
        ></textarea>
        <button
          v-c-tooltip="{
            content: 'پاک کردن پیام',
            placement: 'left',
          }"
          @click="clearForm()"
        >
          <i class="fa fa-eraser" aria-hidden="true"></i>
        </button>
        <label
          for="inputTag"
          v-c-tooltip="{
            content: 'پیوست فایل',
            placement: 'left',
          }"
        >
          <i class="fa fa-paperclip" aria-hidden="true"></i>
          {{ fileAttachedCount }}
          <input id="inputTag" type="file" multiple @change="onFileChange" />
        </label>
        <button
          v-c-tooltip="{
            content: 'ثبت و ارسال',
            placement: 'left',
          }"
          @click="submitReply()"
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../../constants/config";

export default {
  name: "tipsMessageForm",
  props: ["tipsId", "farmTitle", "farmId"],
  watch: {},
  components: {},
  data() {
    return {
      loading: false,
      tipsInfo: {
        id: null,
        title: "",
        description: "",
        insertDate: null,
        updateDate: null,
      },
      tipsReply: [],
      fileName: [],
      files: null,
      description: null,
      fileAttachedCount: 0,
      replyId: null,
      replyDesc: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllTipsAndReply", "NewTipsReply"]),
    async GetTipsInfo() {
      this.loading = true;
      var result = await this.GetAllTipsAndReply({
        id: this.tipsId,
      });
      console.log("GetTipsInfo", result);
      this.loading = false;
      if (result.succeeded) {
        this.tipsInfo = {
          id: result.data.id,
          title: result.data.title,
          description: result.data.description,
          insertDate: result.data.insertDate,
          updateDate: result.data.updateDate,
        };
        result.data.files.forEach((element) => {
          this.fileName.push(`${apiUrlRoot}/TipsFile/${element}`);
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    async submitReply() {
      let formData = new FormData();
      formData.append("tipsId", this.tipsId);
      formData.append("farmId", this.farmId);
      formData.append("childId", this.replyId);
      formData.append("description", this.description);
      this.files.forEach((element) => {
        formData.append("Files", element);
      });
      let newTips = await this.NewTipsReply({
        tipsReply: formData,
      });
      if (newTips) {
        this.clearForm();
        this.$emit("closeModal", true);
        this.$notify({
          title: "پیام سیستم",
          text: ` ثبت دستورالعمل انجام شد <br /><br />`,
          type: "success",
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در ثبت دستورالعمل  <br /><br />`,
          type: "error",
        });
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileAttachedCount = files.length;
      this.files = files;
    },
    clearForm() {
      this.files = null;
      this.description = null;
      this.fileAttachedCount = 0;
    },
    replyTips(id, desc) {
      this.replyId = id;
      this.replyDesc = desc;
    },
  },
  mounted() {
    this.GetTipsInfo();
  },
};
</script>

<style scoped>
.main-box {
  width: 70vw;
  height: 80vh;
}
.info-box {
  float: right;
  width: 40%;
  height: 78vh;
}
.reply-box {
  float: right;
  width: 60%;
  height: 78vh;
}

.tips-info {
  float: right;
  width: 98%;
  height: 78vh;
  box-shadow: 0px 2px 5px -3px #000;
  border-radius: 10px;
  margin: 1%;
  border-top: 1px solid whitesmoke;
}
.tips-info h3 {
  float: right;
  width: 100%;
  text-align: center;
  color: darkgreen;
  font-family: "Koodak" !important;
}
.tips-info p {
  float: right;
  width: 98%;
  margin: 1%;
  text-align: right;
  height: 64vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.tips-info h6 {
  float: right;
  clear: right;
  margin: 2px 1em;
  color: darkgreen;
  font-weight: bold;
}
.tips-info a {
  float: left;
  width: 2vw;
  height: 3vh;
  background-color: #f80;
  margin: 0px 0.3em;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  padding-top: 0.5em;
}

.tips-reply-list {
  float: right;
  width: 98%;
  height: 60vh;
  background-color: white;
  box-shadow: 0px -2px 8px -4px #000 inset;
  margin: 1%;
  border-radius: 4px 4px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tips-reply-list > h5 {
  width: 100%;
  text-align: center;
  margin-top: 6em;
  color: #ababab;
  font-family: "Koodak";
  font-size: 1.7em;
}
.reply-info {
  width: 95%;
  height: fit-content;
  float: right;
  clear: both;
  background-color: #ec53b0;
  margin: 2% 5px;
  padding: 0.5em;
  border-radius: 19px;
  color: #fff;
}
.reply-info button {
  float: left;
  width: fit-content;
  clear: left;
  background-color: #f985cc;
  color: #fff;
  border: none;
  border-radius: 13px;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.2em 1.4em;
}
.reply-info h5 {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  font-weight: normal;
  padding: 0.6em;
}
.reply-info a {
  float: right;
  margin: 0.2em;
  color: #fff;
}

.reply-info-answer {
  width: 95%;
  height: fit-content;
  float: left;
  clear: both;
  background-color: #344eff;
  margin: 2% 5px;
  padding: 0.5em;
  border-radius: 19px;
  color: #fff;
}
.reply-info-answer button {
  float: right;
  width: fit-content;
  clear: right;
  background-color: #5d71fd;
  color: #fff;
  border: none;
  border-radius: 13px;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.2em 1.4em;
}
.reply-info-answer h5 {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  font-weight: normal;
  padding: 0.6em;
}
.reply-info-answer a {
  float: left;
  margin: 0.2em;
  color: #fff;
}
.add-reply {
  float: right;
  width: 100%;
  height: 17vh;
}
.add-reply input[type="file"] {
  display: none;
}

.add-reply textarea {
  float: right;
  width: 85%;
  height: 17vh;
  margin-right: 1%;
  border: 1px solid #bbb;
  resize: none;
  outline: none;
  padding: 0.5em;
}
.add-reply button {
  float: left;
  clear: left;
  width: 4vw;
  height: 4vh;
  border: none;
  background-color: rgb(89, 255, 0);
  margin: 0.5em;
  border-radius: 30px;
  margin-left: 1em;
  font-size: 1em;
  border: 2px solid rgb(89, 255, 0);
}
.add-reply button:hover {
  background-color: #fff;
  color: darkgreen;
  border: 2px solid rgb(89, 255, 0);
}
.add-reply label {
  float: left;
  clear: left;
  width: 4vw;
  height: 4vh;
  border: none;
  background-color: rgb(89, 255, 0);
  margin: 0.5em;
  border-radius: 30px;
  margin-left: 1em;
  font-size: 1em;
  border: 2px solid rgb(89, 255, 0);
  cursor: pointer;
  text-align: center;
}
.add-reply label i {
  font-size: 1.5em;
  color: #000;
  margin-top: 0.3em;
}
.add-reply label:hover {
  background-color: #fff;
  color: darkgreen;
  border: 2px solid rgb(89, 255, 0);
}

.add-reply h6 {
  width: 100%;
  text-align: right;
  font-size: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
