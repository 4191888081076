var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-box"},[_c('vue-element-loading',{attrs:{"active":_vm.loading,"text":"دریافت اطلاعات  ... ","spinner":"bar-fade-scale","color":"var(--secondary)"}}),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"tips-info"},[_c('h3',[_vm._v(_vm._s(_vm.tipsInfo.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.tipsInfo.description)+" ")]),_c('h6',[_vm._v("مالک (زمین) : "+_vm._s(_vm.farmTitle))]),_c('h6',[_vm._v("تاریخ ثبت : "+_vm._s(_vm.tipsInfo.insertDate))]),_c('h6',[_vm._v("تاریخ ویرایش :"+_vm._s(_vm.tipsInfo.updateDate))]),_vm._l((_vm.fileName),function(item,index){return _c('a',{key:index,attrs:{"href":item,"target":"_blank"}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})])})],2)]),_c('div',{staticClass:"reply-box"},[_c('div',{staticClass:"tips-reply-list"},[(_vm.tipsReply.length > 0)?_c('div',_vm._l((_vm.tipsReply),function(item,index){return _c('div',{key:index},[_vm._m(0,true),_vm._m(1,true)])}),0):_c('h5',[_vm._v("هیچ پاسخی ثبت نشده است")])]),_c('div',{staticClass:"add-reply"},[(_vm.replyId)?_c('h6',[_vm._v(" "+_vm._s(_vm.replyDesc)+" ")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],attrs:{"cols":"30","rows":"10","placeholder":"متن پیام را وارد کنید ..."},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing)return;_vm.description=$event.target.value}}}),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'پاک کردن پیام',
          placement: 'left',
        }),expression:"{\n          content: 'پاک کردن پیام',\n          placement: 'left',\n        }"}],on:{"click":function($event){return _vm.clearForm()}}},[_c('i',{staticClass:"fa fa-eraser",attrs:{"aria-hidden":"true"}})]),_c('label',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'پیوست فایل',
          placement: 'left',
        }),expression:"{\n          content: 'پیوست فایل',\n          placement: 'left',\n        }"}],attrs:{"for":"inputTag"}},[_c('i',{staticClass:"fa fa-paperclip",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.fileAttachedCount)+" "),_c('input',{attrs:{"id":"inputTag","type":"file","multiple":""},on:{"change":_vm.onFileChange}})]),_c('button',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'ثبت و ارسال',
          placement: 'left',
        }),expression:"{\n          content: 'ثبت و ارسال',\n          placement: 'left',\n        }"}],on:{"click":function($event){return _vm.submitReply()}}},[_c('i',{staticClass:"fa fa-paper-plane",attrs:{"aria-hidden":"true"}})])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reply-info"},[_c('h5',[_vm._v(" در همان حال کار آنها به نوعی وابسته به متن می‌باشد آنها با استفاده از محتویات ساختگی، صفحه گرافیکی خود را صفحه‌آرایی می‌کنند تا مرحله طراحی و صفحه‌بندی را به پایان برند. ")]),_c('button',[_vm._v("پاسخ به پیام")]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reply-info-answer"},[_c('h5',[_vm._v(" آزمایشی و بی‌معنی استفاده می‌کنند تا صرفا به مشتری یا صاحب کار خود نشان دهند که صفحه طراحی یا صفحه بندی شده بعد از اینکه متن در آن قرار گیرد چگونه به نظر می‌رسد و قلم‌ها و اندازه‌بندی‌ها چگونه در نظر گرفته شده‌است. از آنجایی که طراحان عموما ")]),_c('button',[_vm._v("پاسخ به پیام")]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}})])])
}]

export { render, staticRenderFns }