<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات  ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h4 v-if="tipsId == 0">ثبت دستورالعمل جدید برای {{ farmTitle }}</h4>
    <h4 v-else>ویرایش دستورالعمل ثبت شده برای {{ farmTitle }}</h4>
    <h5>تاریخ ثبت دستورالعمل : {{ insertDate }}</h5>
    <input
      type="text"
      class="main-box-input"
      v-model="title"
      placeholder="عنوان دستورالعمل را وارد کنید ... "
    />
    <textarea
      class="main-box-input"
      cols="30"
      rows="10"
      v-model="description"
      placeholder="توضیحات دستورالعمل را وارد کنید ..."
    ></textarea>
    <label for="inputTag" v-if="tipsId == 0">
      <i class="fa fa-paperclip" aria-hidden="true"></i>
      {{ fileAttached }}
      <input id="inputTag" type="file" multiple @change="onFileChange" />
    </label>
    <div class="file-uploaded-name">
      <ul>
        <li
          v-if="fileName.length == 0"
          style="width: 100%; text-align: center; height: 2vh"
        >
          هیچ فایلی پیوست نشده است
        </li>
        <li v-else-if="fileName.length > 0 && tipsId > 0">
          <div v-for="(item, index) of fileName" :key="index">
            <a v-if="tipsId > 0" :href="item.name" target="_blank">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
            <i
              v-if="tipsId > 0"
              @click="deleteFile(item.id, index)"
              class="fa fa-trash"
              aria-hidden="true"
            ></i>
          </div>
        </li>
        <li
          v-else
          style="width: 90%; text-align: center; height: 2vh; margin: 2%"
        >
          <div v-for="(item, indexs) of fileName" :key="indexs">{{ item }}</div>
        </li>
      </ul>
    </div>
    <button @click="submitTips()" v-if="tipsId == 0">ثبت دستورالعمل</button>
    <button @click="submitTips()" v-else>ویرایش دستورالعمل</button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";

export default {
  name: "newTipsForm",
  props: ["farmId", "farmTitle", "tipsId"],
  watch: {
    tipsId: function () {
      this.featchData(this.tipsId);
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      tipsList: [],
      fileAttached: "پیوست فایل به دستورالعمل ...",

      title: null,
      description: null,
      files: null,
      insertDate: null,
      fileName: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["NewTips", "EditTips", "GetTips", "DeleteTipsFile"]),
    async featchData(id) {
      this.loading = true;
      let tips = await this.GetTips({
        id: id,
      });
      this.loading = false;
      if (tips.succeeded) {
        this.title = tips.data.title;
        this.description = tips.data.description;
        this.insertDate = tips.data.InsertDate;
        tips.data.files.forEach((element) => {
          this.fileName.push({
            id: element.item1,
            name: `${apiUrlRoot}/TipsFile/${element.item2}`,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${tips.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    getCurrentDateTime() {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return new Date().toLocaleDateString("fa-IR", options);
    },
    async submitTips() {
      this.loading = true;
      if (this.tipsId > 0) {
        let updateModel = {
          id: this.tipsId,
          title: this.title,
          description: this.description,
        };
        let updateTips = await this.EditTips({
          editTips: updateModel,
        });
        if (updateTips) {
          this.$emit("closeModal", false);
          this.$notify({
            title: "پیام سیستم",
            text: `ویرایش دستورالعمل انجام شد <br /><br />`,
            type: "success",
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در ویرایش دستورالعمل  <br /><br />`,
            type: "error",
          });
        }
      } else {
        let formData = new FormData();
        formData.append("id", this.tipsId);
        formData.append("farmId", this.farmId);
        formData.append("title", this.title);
        formData.append("description", this.description);
        if (this.files) {
          this.files.forEach((element) => {
            formData.append("Files", element);
          });
        }
        let newTips = await this.NewTips({
          tips: formData,
        });
        if (newTips) {
          this.$emit("closeModal", false);
          this.$notify({
            title: "پیام سیستم",
            text: ` ثبت دستورالعمل انجام شد <br /><br />`,
            type: "success",
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در ثبت دستورالعمل  <br /><br />`,
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileAttached = `${files.length} فایل پیوست شده است`;
      this.files = files;
      this.files.forEach((element) => {
        this.fileName.push(element.name);
      });
    },
    async deleteFile(id, index) {
      this.$confirm({
        message: `فایل پیوست حذف شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            let deleteRow = await this.DeleteTipsFile({
              id: id,
            });
            this.$emit("closeModal", true);
            this.loading = false;
            if (deleteRow.succeeded) {
              this.fileName.splice(index, 1);
              this.$notify({
                title: "پیام سیستم",
                text: `حذف فایل انجام شد <br /><br />`,
                type: "success",
              });
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `خطا در حذف فایل  <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
  },
  mounted() {
    if (this.tipsId > 0) {
      this.featchData(this.tipsId);
    } else {
      this.insertDate = this.getCurrentDateTime();
    }
  },
  created() {},
};
</script>

<style scoped>
.main-box {
  width: 50vw;
  height: 60vh;
}

.main-box h4 {
  width: 100%;
  text-align: CENTER;
  font-size: 1.2em;
  color: #416d19;
  background-color: #bfea7c;
  padding: 0.3em 0em;
  border-radius: 10em;
}
.main-box h5 {
  font-size: 1em;
  color: #a760ff;
  font-weight: bold;
}
.main-box button {
  float: left;
  border: none;
  width: 9vw;
  height: 5vh;
  margin: 3em 1em;
  background-color: darkgreen;
  color: #fff;
  border-radius: 4px;
  transition: all 500ms;
  border: 1px solid darkgreen;
}
.main-box button:hover {
  background-color: #fff;
  color: darkgreen;
}
.main-box-input {
  float: right;
  clear: both;
  width: 97%;
  margin: 1%;
  border: none;
  box-shadow: 0px 0px 9px -4px #000;
  border-radius: 7px;
  padding: 0.6em 0.5em;
  outline: none;
  resize: none;
}

input[type="file"] {
  display: none;
}

label {
  float: right;
  cursor: pointer;
  width: 15vw;
  margin: 1em 2em 0px 0px;
  background-color: #6420aa;
  color: #fff;
  border-radius: 10px;
  position: relative;
  text-align: center;
  height: 3vh;
  border: 2px solid #6420aa;
  transition: all 500ms;
}
label:hover {
  color: #6420aa;
  background-color: #fff;
}
label:hover i {
  color: #fff;
  background-color: #6420aa;
}
label i {
  float: right;
  font-size: 1.6em;
  background-color: #6420aa;
  position: absolute;
  top: -0.3em;
  right: -0.8em;
  border-radius: 100%;
  height: 4vh;
  width: 2vw;
  padding: 0.3em;
}

.file-uploaded-name {
  float: right;
  width: 22vw;
  height: 12vh;
  background-color: whitesmoke;
  clear: both;
  margin-top: 1em;
  border: 1px solid #bbb;
  padding-top: 0.4em;
  overflow-y: auto;
  overflow-x: auto;
}
.file-uploaded-name ul li {
  float: right;
  list-style: none;
  width: 3vw;
  height: 5.5vh;
  box-shadow: 0px 3px 12px -5px #000;
  background-color: #fff;
  border-radius: 11px;
  margin: 0em 0.5em;
}
.file-uploaded-name ul {
  float: right;
  margin: 0px;
  padding: 0px;
}
.file-uploaded-name ul li a {
  float: right;
  width: 77%;
  text-align: center;
  padding: 0.1em 0em 0em 0em;
  background-color: green;
  color: #fff;
  margin: 0.3em 0.4em;
  height: 2vh;
  cursor: pointer;
}
.file-uploaded-name ul li > i {
  float: right;
  width: 77%;
  text-align: center;
  padding: 0.1em 0em 0em 0em;
  background-color: #e55353;
  color: #fff;
  margin: 0em 0.4em;
  height: 2vh;
  cursor: pointer;
}
</style>
