<template>
  <div class="tips-box main-card h-100">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست دستورالعمل ها ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="new-tips">
      <button v-if="farmId > 0 && tipsList.length > 0" @click="newTips(0)">
        <i class="fa fa-plus" aria-hidden="true"></i>
        {{ title }} جدید
      </button>
      <h4>{{ farmerName }}</h4>
      <div class="show-list-state y-center-g-10">
        <h6>{{ allTips ? "نمایش دستورالعمل های من" : "نمایش تمام دستورالعمل ها" }}</h6>
        <CSwitch variant="3d" size="sm" color="info" :checked.sync="allTips" />
      </div>
    </div>
    <hr />
    <div class="tips-list">
      <div v-if="tipsList.length == 0" class="text-center p-5 vertical-g-20">
        هیچ {{ title }} ای ثبت نکرده اید
        <button @click="newTips(0)" v-if="farmId > 0">
          ثبت {{ title }} جدید
        </button>
        <no-data
          show
          :message="`برای ثبت ${title} یک زمین را انتخاب کنید`"
          v-else
        >
        </no-data>
      </div>
      <div v-else class="tips-list-box">
        <div class="tips-item" v-for="(item, index) of tipsList" :key="index">
          <h2>{{ item.title }}</h2>
          <div class="tips-item-info">
            <h4>زمین :</h4>
            <h5>{{ item.farmName }}</h5>
            <h4>بخشی از توضیحات :</h4>
            <h6>
              {{ item.description }}
            </h6>
            <h4>پاسخ ها :</h4>
            <h5>{{ item.replyCount }} عدد</h5>
            <h4>ثبت کننده :</h4>
            <h5 dir="ltr">
              {{ item.currentUser ? "خودتان ثبت کرده اید" : item.userCreator }}
            </h5>
            <h4>تاریخ ثبت :</h4>
            <h5 dir="ltr">{{ item.insertDate }}</h5>
            <h4>تاریخ ویرایش :</h4>
            <h5 dir="ltr">{{ item.updateDate }}</h5>
          </div>
          <button
            v-c-tooltip="{
              content: 'نمایش پیام ها',
              placement: 'top',
            }"
            @click="tipsReply(item.id)"
            v-if="farmId > 0"
          >
            <i class="fa fa-comments-o" aria-hidden="true"></i>
          </button>
          <button
            v-c-tooltip="{
              content: 'ویرایش',
              placement: 'top',
            }"
            @click="newTips(item.id)"
            v-if="farmId > 0 && item.currentUser"
          >
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <a
            v-for="(f, i) of item.files"
            :key="i"
            :href="downloadLink(f)"
            v-c-tooltip="{
              content: `دانلود فایل پیوست ${i + 1}`,
              placement: 'top',
            }"
            target="_blank"
          >
            <i class="fa fa-cloud-download" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <VueModal
      :title="modelTitle"
      v-model="modelState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
    >
      <component
        v-if="subForm != null"
        @closeModal="refreshList"
        v-bind:is="subForm"
        :farmId="id"
        :farmTitle="farmName"
        :tipsId="tipsId"
      />
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tipsItem from "./tipsItem";
import tipsMessage from "./tipsMessage";
import { apiUrlRoot } from "../../../constants/config";
import { getCurrentUser } from "../../../utils/index";
import NoData from "../../../components/no-data.vue";

export default {
  name: "tipsForm",
  props: ["farmId", "farmerName", "farmName"],
  watch: {
    farmId: function () {
      this.GetAllTipsList();
    },
    allTips: function () {
      this.GetAllTipsList();
    },
  },
  components: {
    NoData,
    tipsItem,
    tipsMessage,
  },
  data() {
    return {
      loading: false,
      modelTitle: "",
      modelState: false,
      tipsList: [],
      subForm: null,
      id: 0,
      tipsId: 0,
      title:
        getCurrentUser().type == "FarmUser"
          ? "دستورالعمل کارمند"
          : "دستورالعمل متخصص",
      allTips: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllTips"]),
    async GetAllTipsList() {
      this.loading = true;
      this.tipsList = [];
      let tipsResult = await this.GetAllTips({
        farmId: this.farmId,
        allTips: this.allTips,
      });
      this.loading = false;
      if (tipsResult.succeeded) {
        this.tipsList = tipsResult.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${tipsResult.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    newTips(id) {
      this.modelTitle =
        id == 0 ? `ثبت ${this.title} جدید ...` : `ویرایش ${this.title} ...`;
      this.subForm = "tipsItem";
      this.id = this.farmId;
      this.modelState = true;
      this.tipsId = id;
    },
    tipsReply(id) {
      this.modelTitle = `لیست پیام ها در مورد ${this.title}`;
      this.subForm = "tipsMessage";
      this.id = this.farmId;
      this.modelState = true;
      this.tipsId = id;
    },
    refreshList(state) {
      this.GetAllTipsList();
      this.modelState = state;
    },
    downloadLink(fileName) {
      return `${apiUrlRoot}/TipsFile/${fileName}`;
    },
  },
  mounted() {
    this.GetAllTipsList();
  },
};
</script>

<style scoped>
.new-tips {
  height: 4vh;
}
.new-tips h4 {
  float: right;
  width: fit-content;
  text-align: right;
  font-size: 1em;
  font-family: "Dirooz";
  font-weight: normal;
  padding: 0.2em 1em 0em 0em;
}
.new-tips button {
  float: right;
  border: none;
  background-color: darkgreen;
  color: #fff;
  padding: 0.1em 1em;
  margin: 0px 1em;
  border-radius: 15px;
} 
.tips-list-box {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.tips-list h1 {
  width: 100%;
  text-align: center;
  margin-top: 8em;
  color: #bbb;
}
.tips-item {
  float: right;
  width: 18vw;
  margin: 0.5em;
  box-shadow: 0px 4px 9px -3px #000;
  border-radius: 5px 5px 0px 0px;
  min-height: 28vh;
}
.tips-item h2 {
  float: right;
  width: 100%;
  margin: 13px 0px;
  text-align: center;
  font-size: 1.4em;
  color: darkgreen;
}
.tips-item-info {
  float: right;
  margin: 0px 4px;
}
.tips-item-info h4 {
  float: right;
  clear: right;
  font-size: 1em;
  font-weight: bold;
  color: #2eb85c;
  margin: 3px 0px;
}
.tips-item-info h5 {
  float: right;
  font-size: 1em;
  margin: 3px 5px;
  color: #636363;
}
.tips-item-info h6 {
  font-size: 1em;
  margin: 3px 5px;
  color: #636363;
  width: 17vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: right;
}
.tips-item button {
  float: left;
  margin: 1%;
  border: none;
  color: cornflowerblue;
  background-color: #e7e7e7;
  height: 3vh;
  width: 2vw;
  padding-top: 0.3em;
  border-radius: 100%;
}
.tips-item button:hover {
  color: #fff;
  background-color: cornflowerblue;
}

.tips-item a {
  float: left;
  margin: 1%;
  border: none;
  color: cornflowerblue;
  background-color: #e7e7e7;
  height: 3vh;
  width: 2vw;
  padding-top: 0.3em;
  border-radius: 100%;
}
.tips-item a i {
  float: right;
  margin-right: 26%;
  margin-top: 6%;
  color: darkolivegreen;
}
.tips-item a:hover {
  color: #fff;
  background-color: cornflowerblue;
}

.show-list-state {
  float: left;
  height: 3vh;
  padding: 2px 1em;
}
.show-list-state * {
  outline: none !important;
}
</style>
